import classNames from 'classnames'
import { useMemo } from 'react'
import { Nav, NavItem, NavLink } from 'reactstrap'

const TabsHeader = ({ activeTab, toggle }) => {
	const tabs = useMemo(
		() => [
			{ title: 'Uploaded Files', id: 1 },
			{ title: 'PDF Fields', id: 2 },
			{ title: 'Documents', id: 3 },
		],
		[],
	)

	return (
		<Nav tabs className='w-fill my-3'>
			{tabs.map((tab, idx) => (
				<NavItem key={idx}>
					<NavLink
						className={classNames('cursor-pointer !text-sm', {
							active: activeTab === tab?.id,
						})}
						onClick={() => toggle(tab?.id)}>
						<span>{tab?.title}</span>
					</NavLink>
				</NavItem>
			))}
		</Nav>
	)
}

export default TabsHeader
