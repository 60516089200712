import { Field, FieldArray } from 'formik'

import Alert from 'theme/ui/alert'
import { CheckboxField, TextareaField } from 'theme/ui/forms'
import AdvisorDropdown from '../advisor-dropdown'
import ClientDropdown from '../client-dropdown'
import CoApplicantField from '../co-applicant-field'
import FinalReviewerDropdown from '../final-reviewer-dropdown'
import ReceiverEmailDropdown from '../receiver-email/receiver-email-dropdown'
import ReviewerDropdown from '../reviewer-dropdown'
import WorkflowDropdown from '../workflow-dropdown'

const Steps = ({
	formValues,
	documents,
	document_packages,
	upload_files,
	advisor_reviewer,
	isCreateLink,
}) => {
	const applicants = Array.isArray(formValues?.co_applicants)
		? formValues?.co_applicants.map((applicant) => applicant?.value)
		: []
	const client_applicants = [formValues?.client?.value, ...applicants].filter(
		(user) => typeof user !== 'undefined',
	)

	return (
		<div className='mx-auto flex w-full justify-center gap-6 bg-white'>
			<div className='form-fields-card space-y-6'>
				<WorkflowDropdown />
				<Alert className='mt-2' variant='warning'>
					<div className='flex items-start space-x-1'>
						<i className='bx bxs-info-circle mr-1 text-base' />
						<p className='m-0 text-[13px]'>
							If you switch the workflow the rest of fields will be updated according
							to the requirement of the workflow.
						</p>
					</div>
				</Alert>
				<AdvisorDropdown formValues={formValues} />

				{isCreateLink ? (
					<span></span>
				) : (
					<div className='space-y-6'>
						<Field
							type='checkbox'
							name='createPublicURL'
							id='createPublicURL'
							component={CheckboxField}
							label='Would you like to generate a public link for this workflow assignment?'
						/>
						<ClientDropdown
							formValues={formValues}
							client_applicants={client_applicants}
							name='client.user'
						/>
						{formValues?.client?.user?.some((item) => item.type === 'household') && (
							<Alert className='mt-2' variant='warning'>
								<div className='flex items-start space-x-1'>
									<i className='bx bxs-info-circle mr-1 text-base' />
									<p className='m-0 text-[13px]'>
										Workflows will be assigned to all clients belonging to the
										selected Group(s) as of today. Changes made to the groups
										after assignment will not affect the workflow assigned to
										the clients.
									</p>
								</div>
							</Alert>
						)}
					</div>
				)}

				{formValues.userCount > 1 && Array.isArray(formValues.co_applicants) ? (
					<FieldArray
						name='co_applicants'
						render={(arrayHelpers) => {
							const isDisabled = applicants.length === formValues.userCount - 1
							return (
								<CoApplicantField
									applicants={applicants}
									arrayHelpers={arrayHelpers}
									isDisabled={isDisabled}
									client_applicants={client_applicants}
									formValues={formValues}
								/>
							)
						}}
					/>
				) : undefined}
			</div>

			<div className='form-fields-card space-y-6'>
				<FinalReviewerDropdown
					formValues={formValues}
					advisor_reviewer={advisor_reviewer}
				/>
				<ReviewerDropdown formValues={formValues} advisor_reviewer={advisor_reviewer} />
				<ReceiverEmailDropdown formValues={formValues} />

				{documents.length > 0 ? (
					<div>
						<span className='text-[14px] font-bold text-[#495057]'>
							Documents Populated
						</span>
						<ul className='m-0 list-disc font-medium'>
							{documents.map((document, idx) => (
								<li key={idx}>{document?.title}</li>
							))}
						</ul>
					</div>
				) : undefined}

				{document_packages.length > 0 ? (
					<div>
						<span className='text-[14px] font-bold text-[#495057]'>
							Document Packages Populated
						</span>
						<ul className='m-0 list-disc font-medium'>
							{document_packages.map((doc_package, idx) => (
								<li key={idx}>{doc_package?.title}</li>
							))}
						</ul>
					</div>
				) : undefined}

				{upload_files.length > 0 ? (
					<ul className='m-0 list-disc'>
						{upload_files.map((file, idx) => (
							<li key={idx}>
								{file?.file} for{' '}
								<span className='font-bold capitalize'>{file?.user_type}</span>
							</li>
						))}
					</ul>
				) : undefined}

				<Field
					label='Notes'
					type='select'
					name='notes'
					component={TextareaField}
					placeholder='Add a note if needed about the workflow for the client...'
				/>
			</div>
		</div>
	)
}

export default Steps
