import { createColumnHelper } from '@tanstack/react-table'
import { DOCUMENT_VERSION_APP_ROUTES } from 'modules/documents/routes'
import { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import { ReactDataTable } from 'theme/ui/data-table'
import ToolTip from 'theme/ui/tool-tip'
import { useLazyGetDocumentVersionsQuery } from '../document-version-api'

const DocumentVersionTable = ({ id }) => {
	const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 })
	const [fetchDocumentVersions, { data, isLoading }] = useLazyGetDocumentVersionsQuery()

	const { versions, meta } = useMemo(
		() => ({
			versions: data?.versions,
			meta: data?.meta,
		}),
		[data],
	)

	useEffect(() => {
		if (pagination && id) {
			const params = {
				page: pagination.pageIndex + 1,
				limit: pagination.pageSize,
			}
			fetchDocumentVersions({ params, id })
		}
	}, [pagination, id])

	const columnHelper = createColumnHelper()

	const columns = [
		columnHelper.accessor('index', {
			header: () => <span>#</span>,
			cell: (info) => <span>{info.getValue()}.</span>,
		}),

		columnHelper.accessor((row) => row, {
			id: 'version_info',
			header: () => <span>Version</span>,
			cell: (info) => {
				console.log(info.getValue())

				return (
					<div>
						<ToolTip
							targetId={`tooltip-${info.getValue()?.id}`}
							tooltipText='Click to view'
						/>

						{info.getValue().is_current ? (
							<div className='flex items-center space-x-1'>
								<Link
									to={DOCUMENT_VERSION_APP_ROUTES.view(
										info.getValue()?.document_id,
										info.getValue()?.id,
									)}
									className='cursor-pointer font-bold uppercase text-main hover:!underline'
									id={`tooltip-${info.getValue()?.id}`}>
									CURRENT
								</Link>
								<span className='font-bold text-gray-700'>
									(v. {info.getValue().version})
								</span>
							</div>
						) : (
							<Link
								to={DOCUMENT_VERSION_APP_ROUTES.view(
									info.getValue()?.document_id,
									info.getValue()?.id,
								)}
								className='cursor-pointer font-semibold text-main hover:!underline'
								id={`tooltip-${info.getValue()?.id}`}>
								v. {info.getValue().version}
							</Link>
						)}
					</div>
				)
			},
		}),

		columnHelper.accessor('created_at', {
			header: () => <span>Published</span>,
			cell: (info) => <span>{info.getValue()}</span>,
		}),

		columnHelper.accessor('created_by', {
			header: () => <span>Changed By</span>,
			cell: (info) => (
				<div className='flex items-center space-x-1'>
					{/* <AsyncImage
						className='mr-2 h-7 w-7 rounded-full'
						src={info.getValue()?.avatar}
						alt={info.getValue()?.fullName}
					/> */}
					<span className='font-normal'>{info.getValue()?.fullName}</span>
				</div>
			),
		}),
	]
	return (
		<div>
			<ReactDataTable
				columns={columns}
				data={versions}
				meta={meta}
				isLoading={isLoading}
				pagination={pagination}
				setPagination={setPagination}
			/>
		</div>
	)
}

export default DocumentVersionTable
