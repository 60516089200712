import ActiveSubscription from './active-subscription'

export function dateDifferenceInDays(date1, date2) {
	const d1 = new Date(date1)
	const d2 = new Date(date2)

	const timeDifference = d2 - d1
	const differenceInDays = timeDifference / (1000 * 3600 * 24)

	return Math.abs(differenceInDays)
}
export const getSubscriptionDays = (duration) => {
	switch (duration) {
		case 'annual':
			return 365

		default:
			return 30
	}
}

export const getRemainingDays = (subscription) => {
	const subscriptionDays = getSubscriptionDays(subscription?.plan?.duration)
	const remainingDays = dateDifferenceInDays(new Date(), subscription?.end_date)

	return {
		subscriptionDays: subscriptionDays,
		remainingDays: Math.floor(remainingDays) || 0,
		remainingPercentage: Math.floor((remainingDays / subscriptionDays) * 100),
	}
}

export { default as CurrentSubscription } from './current-subscription'
export { default as PaymentsDetails } from './payments-details'
export { default as UsageSubscription } from './usage-subscription'

export default ActiveSubscription
