import { Field, FieldArray } from 'formik'
import { InputField, SelectField } from 'theme/ui/forms'

const DocumentUploadSection = ({ formValues, userTypeOptions }) => {
	return (
		<FieldArray
			name='files'
			render={(arrayHelpers) => (
				<div>
					<div className='mb-3 flex items-center justify-between'>
						<h4 className='text-base font-bold'>
							Documents for Upload <span className='text-sm font-normal'>(Optional)</span>
						</h4>

						<button
							type='button'
							className='button btn-primary-outline'
							onClick={() =>
								arrayHelpers.push({
									user_type: '',
									file: '',
								})
							}>
							<div className='flex items-center space-x-1 '>
								<span>
									<i className='uil-plus' />
								</span>
								<span>Add New</span>
							</div>
						</button>
					</div>
					{formValues.files.length > 0 ? (
						<div className='flex w-full flex-col space-y-4'>
							{formValues.files.map((file, idx) => (
								<div key={idx} className='flex w-full items-center space-x-8'>
									<Field
										type='select'
										label='Select User Type'
										name={`files[${idx}].user_type`}
										options={userTypeOptions}
										component={SelectField}
										className='min-w-[166px]'
									/>
									<div className='flex items-end space-x-2'>
										<Field
											type='text'
											placeholder='Enter File Name'
											label='Enter File Name'
											name={`files[${idx}].file`}
											component={InputField}
										/>
										<button
											type='button'
											onClick={() => arrayHelpers.remove(idx)}
											className='rounded bg-gray-100 px-2 py-1.5 text-red-500 hover:bg-gray-200'>
											<i className='fas fa-trash' />
										</button>
									</div>
								</div>
							))}
						</div>
					) : (
						<p className='text-center text-gray-500'>No document is added yet.</p>
					)}
				</div>
			)}
		/>
	)
}

export default DocumentUploadSection
