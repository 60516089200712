/* eslint-disable react-hooks/exhaustive-deps */
import { snakeCase } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Container } from 'reactstrap'

import { useUser } from 'hooks'
import AppLayout from 'theme/layouts/app-layout'
import AnnouncementsList from 'theme/ui/announcements'
import NylasNotification from 'theme/ui/nylas-notification'
import Home from './home'

const Dashboard = () => {
	const { isSuperAdmin, defaultGroup } = useSelector((state) => state.Permissions)
	const currentGroup = useMemo(() => snakeCase(defaultGroup?.group?.name), [defaultGroup])
	const { team } = useUser()
	useEffect(() => {
		if (!isSuperAdmin && currentGroup === 'client') {
			// history.push('/assigned-workflows')
		}
	}, [currentGroup])

	const [showMessage, setShowMessage] = useState(false)

	useEffect(() => {
		if (!team?.nylasGrantId) {
			setShowMessage(true)
		}
	}, [team])

	return (
		<AppLayout
			meta={{
				title: 'Dashboard',
			}}>
			<div className='page-content'>
				<Container fluid>
					<h4>Dashboard</h4>
					{!isSuperAdmin && (
						<>
							{showMessage && currentGroup === 'owner' && (
								<NylasNotification setShowMessage={setShowMessage} />
							)}
							<AnnouncementsList />
							{/* <Notes /> */}
							<Home />
						</>
					)}
				</Container>
			</div>
		</AppLayout>
	)
}
export default Dashboard
