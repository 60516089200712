import { Menu, Transition } from '@headlessui/react'
import classNames from 'classnames'
import { Field, getIn } from 'formik'
import { Fragment } from 'react'

import Datepicker from './datepicker'

const DateRange = ({ name, options }) => {
	return (
		<Field name={name}>
			{({ form }) => {
				const value = getIn(form?.values, name)
				const selectedOption = options.find((option) => option.value === value)
				return (
					<div className='max-w-fit'>
						<Menu as='div' className='relative inline-block text-left'>
							{({ open }) => (
								<>
									<Menu.Button
										className={classNames('btn btn-secondary', {
											'!bg-main/20 !text-main !shadow-md': open,
										})}>
										<span className='font-normal text-gray-500 display_none'>
											{selectedOption?.label || 'Select Date'}
										</span>
										<span className='ms-2'>
											<i className='far fa-calendar text-gray-500' />
										</span>
									</Menu.Button>
									<Transition
										as={Fragment}
										enter='transition ease-out duration-100'
										enterFrom='transform opacity-0 scale-95'
										enterTo='transform opacity-100 scale-100'
										leave='transition ease-in duration-75'
										leaveFrom='transform opacity-100 scale-100'
										leaveTo='transform opacity-0 scale-95'>
										<Menu.Items
											className={classNames(
												'absolute right-0 z-[999] mt-2 origin-top-right divide-y divide-gray-100 rounded-md !border  bg-white shadow-lg ring-black/5 focus:outline-none',
											)}>
											<div className='w-[144px] space-y-1 p-1'>
												{options.map((option, idx) => (
													<Menu.Item key={idx}>
														{({ active }) => (
															<button
																type='button'
																onClick={() => {
																	form.setFieldValue(
																		name,
																		option?.value,
																	)
																}}
																className={classNames(
																	'group flex w-full items-center rounded px-3 py-2 text-sm font-normal text-[#333]',
																	{
																		'bg-main/10': active,
																		'bg-main/20 text-main':
																			option?.value === value,
																	},
																)}>
																{option?.label}
															</button>
														)}
													</Menu.Item>
												))}

												<Menu.Item disabled={true}>
													{() => (
														<div
															className={classNames(
																'flex w-full items-center rounded-b text-sm font-normal text-[#333] hover:bg-main/10 active:bg-main/20',
															)}>
															<Datepicker
																onChange={(value) => {
																	// eslint-disable-next-line no-unused-vars
																	const [_, end] =
																		value.split(',')
																	if (
																		new Date(end) !==
																		'Invalid Date'
																	) {
																		form.setFieldValue(
																			name,
																			value,
																		)
																	}
																}}
																value={value}
															/>
														</div>
													)}
												</Menu.Item>
											</div>
										</Menu.Items>
									</Transition>
								</>
							)}
						</Menu>
					</div>
				)
			}}
		</Field>
	)
}

export default DateRange
