import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect, useSelector } from 'react-redux'
import { Link, Redirect, withRouter } from 'react-router-dom'
import { Dropdown } from 'reactstrap'

import experiviseDarkLogo from 'assets/images/experivise-logo-dark.png'
import logoLight from 'assets/images/logo-light.png'
import logoSm from 'assets/images/logo-sm.png'
import { Loader } from 'components/Common'
import ProfileMenu from 'components/CommonForBoth/TopbarDropdown/ProfileMenu'
import ClientOnBoardingNavBar from 'components/VerticalLayout/ClientOnBoardingNavBar'
import { fetchPermissions } from 'modules/authentication/login'
import { Notifications } from 'modules/notifications'
import OwnerInvites from 'modules/owner-invites'
import useStepsQuery from 'pages/Authentication/client-document-upload/components/useStepsQuery'
import { changeSidebarType, showRightSidebarAction, toggleLeftmenu } from 'store/actions'
import { Button } from 'theme/ui/forms'
import OffCanvas from 'theme/ui/off-canvas'

const Header = () => {
	const [isFullScreen, setIsFullScreen] = useState(false)
	const { defaultGroup, isSuperAdmin } = useSelector((state) => state.Permissions)
	const { onboardStatus, isLoading } = useStepsQuery()

	const $currentOnbardingStatus = useMemo(() => {
		const $onboardStatus = typeof onboardStatus !== 'undefined' && onboardStatus !== 'Approved'
		const $isLoading = !isLoading
		const $defaultGroup = defaultGroup?.group?.slug === 'client'
		const $isSuperAdmin = !isSuperAdmin

		return $onboardStatus && $isLoading && $defaultGroup && $isSuperAdmin
	}, [onboardStatus, onboardStatus, defaultGroup, isSuperAdmin])

	useEffect(() => {
		fetchPermissions().finally(() => {})
	}, [])

	const [isOnBoardingScreen, setIsOnBoardingScreen] = useState(true)
	const segmentFirst = window.location.pathname.split('/')[1] || null

	useEffect(() => {
		if (segmentFirst === 'onboarding') {
			setIsOnBoardingScreen(true)
		} else {
			setIsOnBoardingScreen(false)
		}
	}, [segmentFirst])

	function toggleFullscreen() {
		if (
			!document.fullscreenElement &&
			/* alternative standard method */ !document.mozFullScreenElement &&
			!document.webkitFullscreenElement
		) {
			setIsFullScreen(true)
			// current working methods
			if (document.documentElement.requestFullscreen) {
				document.documentElement.requestFullscreen()
			} else if (document.documentElement.mozRequestFullScreen) {
				document.documentElement.mozRequestFullScreen()
			} else if (document.documentElement.webkitRequestFullscreen) {
				document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT)
			}
		} else {
			setIsFullScreen(false)
			if (document.cancelFullScreen) {
				document.cancelFullScreen()
			} else if (document.mozCancelFullScreen) {
				document.mozCancelFullScreen()
			} else if (document.webkitCancelFullScreen) {
				document.webkitCancelFullScreen()
			}
		}
	}

	function tToggle() {
		var body = document.body
		body.classList.toggle('vertical-collpsed')
		body.classList.toggle('sidebar-enable')
	}

	const [isOffCanvasOpen, setIsOffCanvasOpen] = useState(false)

	const handleToggleOffCanvas = () => {
		setIsOffCanvasOpen(!isOffCanvasOpen)
	}

	console.log(
		$currentOnbardingStatus,
		typeof onboardStatus !== 'undefined',
		onboardStatus !== 'Approved',
	)

	if (isLoading) {
		return <Loader />
	} else if (
		!isLoading &&
		$currentOnbardingStatus &&
		typeof onboardStatus !== 'undefined' &&
		onboardStatus !== 'Approved'
	) {
		return <Redirect to={{ pathname: '/onboarding/review-information' }} />
	} else if (!isLoading && $currentOnbardingStatus) {
		return <Redirect to={{ pathname: '/onboarding/required-document-list' }} />
	}

	return (
		<React.Fragment>
			{isOnBoardingScreen ? (
				<ClientOnBoardingNavBar />
			) : (
				<header id='page-topbar'>
					<div className='navbar-header'>
						<div className='d-flex'>
							<div className='navbar-brand-box'>
								<Link
									to='/dashboard'
									className='logo logo-dark  flex items-center justify-center'>
									<span className='logo-sm'>
										<img
											src={experiviseDarkLogo}
											alt=''
											style={{ height: 22, marginTop: 25 }}
										/>
									</span>
									<span className='logo-lg'>
										<img
											src={experiviseDarkLogo}
											alt=''
											style={{ height: 20 }}
										/>
									</span>
								</Link>

								<Link to='/dashboard' className='logo logo-light'>
									<span className='logo-sm'>
										<img src={logoSm} alt='' height='22' />
									</span>
									<span className='logo-lg'>
										<img src={logoLight} alt='' height='20' />
									</span>
								</Link>
							</div>

							<button
								type='button'
								onClick={() => {
									tToggle()
								}}
								className='btn btn-sm font-size-16 header-item waves-effect vertical-menu-btn px-3'
								id='vertical-menu-btn'>
								<i className='fa fa-fw fa-bars' />
							</button>
						</div>

						<div className='flex items-center space-x-3'>
							{isSuperAdmin ? (
								<>
									<Button
										variant='primary-outline'
										size='sm'
										type='button'
										onClick={handleToggleOffCanvas}>
										Invite Owner
									</Button>
									<OffCanvas
										width='!w-[400px]'
										isOpen={isOffCanvasOpen}
										onClose={handleToggleOffCanvas}>
										<OwnerInvites onClose={handleToggleOffCanvas} />
									</OffCanvas>
								</>
							) : undefined}

							<Dropdown className='d-none d-lg-inline-block ms-1'>
								<button
									type='button'
									onClick={() => {
										toggleFullscreen()
									}}
									className='btn header-item noti-icon waves-effect'
									data-toggle='fullscreen'>
									{isFullScreen ? (
										<i className='uil-compress'></i>
									) : (
										<i className='uil-minus-path'></i>
									)}
								</button>
							</Dropdown>
							<Notifications />

							<ProfileMenu />
						</div>
					</div>
				</header>
			)}
		</React.Fragment>
	)
}

Header.propTypes = {
	changeSidebarType: PropTypes.func,
	leftMenu: PropTypes.any,
	leftSideBarType: PropTypes.any,
	showRightSidebar: PropTypes.any,
	showRightSidebarAction: PropTypes.func,
	t: PropTypes.any,
	toggleLeftmenu: PropTypes.func,
}

const mapStatetoProps = (state) => {
	const { layoutType, showRightSidebar, leftMenu, leftSideBarType } = state.Layout
	return { layoutType, showRightSidebar, leftMenu, leftSideBarType }
}

export default withRouter(
	connect(mapStatetoProps, {
		showRightSidebarAction,
		toggleLeftmenu,
		changeSidebarType,
	})(withTranslation()(Header)),
)
