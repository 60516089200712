import { createColumnHelper } from '@tanstack/react-table'
import { useState } from 'react'

import DataTable from 'theme/ui/data-table'
import Actions from './actions'

const WorkflowsTemplatesTable = ({ workflows, meta, isLoading, pagination, setPagination }) => {
	const [actionActiveId, setActionActiveId] = useState(null)
	const [sorting, setSorting] = useState([])
	const onMouseEnter = (id) => setActionActiveId(id)
	const onMouseLeave = () => setActionActiveId(null)

	const columnHelper = createColumnHelper()

	const columns = [
		// columnHelper.accessor('index', {
		// 	id: 'index',
		// 	size: 40,
		// 	header: () => <span>#</span>,
		// 	cell: (info) => <Fragment>{info.getValue()}.</Fragment>,
		// }),

		columnHelper.accessor((row) => row.title, {
			id: 'title',
			size: 200,
			header: () => <span style={{ minWidth: '100px' }}>Workflow Title</span>,
			cell: (info) => (
				<span
					style={{ minWidth: '100px' }}
					className='line-clamp-1 text-sm font-bold !text-gray-700'
					title={info.getValue()}>
					{info.getValue()}
				</span>
			),
		}),

		// columnHelper.accessor((row) => row.users, {
		// 	id: 'users',
		// 	size: 100,
		// 	header: () => <span>Users</span>,
		// 	cell: (info) => (
		// 		<span className='line-clamp-1 max-w-[180px] text-sm !text-gray-700'>
		// 			{info.getValue()}
		// 		</span>
		// 	),
		// }),

		columnHelper.accessor((row) => row.created_by, {
			id: 'created_by',
			header: () => <span style={{ minWidth: '100px' }}>Created By</span>,
			cell: (info) => (
				<span style={{ minWidth: '100px' }} className='max-w-[180px] text-sm !text-gray-700'>{info.getValue()}</span>
			),
		}),

		columnHelper.accessor((row) => row, {
			id: 'last_updated',
			size: 180,
			header: () => <span style={{ minWidth: '100px' }}>Last Updated</span>,
			cell: (info) => <span style={{ minWidth: '100px' }}>{info.getValue()?.last_updated}</span>,
		}),

		columnHelper.accessor((row) => row, {
			id: 'actions',
			size: 180,
			header: () => <span>Actions</span>,
			cell: (info) => <Actions key={info.getValue()?.id} workflow={info.getValue()} />,
		}),
	]

	return (
		<DataTable
			columns={columns}
			data={workflows || []}
			isLoading={isLoading}
			pagination={pagination}
			setPagination={setPagination}
			setSorting={setSorting}
			sorting={sorting}
			meta={meta}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
		/>
	)
}

export default WorkflowsTemplatesTable
