import { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { Col, Container, Row } from 'reactstrap'
import errorImg from '../../assets/images/404-error.png'

const ResourceNotFound = ({ title, description }) => {
	return (
		<Fragment>
			<div className='pt-sm-5 my-5'>
				<Container>
					<Row>
						<Col md={12}>
							<div className='text-center'>
								<div>
									<Row className='row justify-content-center'>
										<Col sm={4}>
											<div className='error-img'>
												<img
													src={errorImg}
													alt=''
													className='img-fluid d-block mx-auto'
												/>
											</div>
										</Col>
									</Row>
								</div>

								{title ? (
									<h4 className='text-uppercase mt-4'>{title}</h4>
								) : undefined}

								{description ? (
									<p className='text-muted'>{description}</p>
								) : undefined}

								<div className='mt-5'>
									<Link
										className='btn btn-primary waves-effect waves-light'
										to='/'>
										Back to Dashboard
									</Link>
								</div>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
		</Fragment>
	)
}

export default ResourceNotFound
