import { WorkflowForm } from 'modules/workflows/workflow-template'
import AppLayout from 'theme/layouts/app-layout'
import PageHeader from 'theme/layouts/page-header'

const WorkflowTemplateCreate = () => {
	return (
		<AppLayout
			meta={{
				title: 'Create Workflow Template',
			}}>
			<div className='page-content px-0 !pt-[70px]'>
				<div className='page-container'>
					<PageHeader
						title='Create Workflow'
						description='When assigning documents/packages here, do not ignore to fill in all the required fields completely and follow the document/package adding rules.'
						hasBackButton={true}
					/>
					<div className='page-body'>
						<WorkflowForm />
					</div>
				</div>
			</div>
		</AppLayout>
	)
}

export default WorkflowTemplateCreate
