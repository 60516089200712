import apiEndpoints from 'helpers/apiEndpoints'
import { findLargestInteger } from 'utilities/helpers'
import notification from 'utilities/notification'
import request from 'utilities/request'
import * as yup from 'yup'
import { createOption } from './assign-documents/documents-dropdown'
import WorkflowForm from './workflow-form'

export const STEPS_TYPE = {
	UPLOAD: 'uploads',
	DOCUMENT: 'individual_document',
}

export const validationSchema = yup.object().shape(
	{
		title: yup.string().required('Please enter workflow title'),
		documents: yup.array().when('document_packages', {
			is: (document_packages) => document_packages?.length === 0,
			then: (schema) =>
				schema
					.min(1, 'Please select at least 1 document')
					.required('Please select a document'),
		}),
		document_packages: yup.array().when('documents', {
			is: (documents) => documents.length === 0,
			then: (schema) =>
				schema
					.min(1, 'Please select at least 1 document package')
					.required('Please select a document package'),
		}),
	},
	['documents', 'document_packages'],
)

export const createUserTypeOptions = (min_users) => {
	try {
		const defaultOptions = [{ label: 'Advisor', value: 'advisor' }]
		const createdOptions = new Array(min_users).fill(0).map((_, idx) => {
			if (idx === 0) {
				return { label: 'Client', value: 'client' }
			} else {
				return { label: `Co Applicant ${idx}`, value: `applicant_${idx}` }
			}
		})
		return [...defaultOptions, ...createdOptions]
	} catch (error) {
		return []
	}
}

export const getUserTypeOptions = (values) => {
	try {
		const min_doc_Clients = values.documents.map((doc) => doc?.value?.minClients)
		const min_package_clients = values.document_packages.map(
			(doc_package) => doc_package?.value?.minClients,
		)
		const minClientsArray = [...min_doc_Clients, ...min_package_clients]
		const min_users = findLargestInteger(minClientsArray)
		return createUserTypeOptions(min_users)
	} catch (error) {
		return []
	}
}

export const getInitialValues = (workflow) => {
	if (!workflow) {
		return undefined
	}

	const steps = Array.isArray(workflow?.steps) ? workflow?.steps : []
	const document_step = steps.find((step) => step?.type === STEPS_TYPE.DOCUMENT)
	const upload_step = steps.find((step) => step?.type === STEPS_TYPE.UPLOAD)

	const min_doc_Clients = document_step.raw_documents.map((doc) => doc?.value?.minClients || 1)
	const min_package_clients = document_step.document_packages.map(
		(doc_package) => doc_package?.value?.minClients || 1,
	)
	const minClientsArray = [...min_doc_Clients, ...min_package_clients]
	const min_users = findLargestInteger(minClientsArray)
	const defaultOptions = createUserTypeOptions(min_users)

	const filesArray = JSON.parse(upload_step?.note) || []
	const files = filesArray.map((file) => {
		return {
			...file,
			user_type: defaultOptions.find((option) => option?.value === file?.user_type),
		}
	})

	return {
		title: workflow?.title,
		documents: document_step?.raw_documents.map((doc) => createOption(doc)),
		document_packages: document_step?.document_packages.map((doc) => createOption(doc)),
		files: files,
		steps: [
			{ type: STEPS_TYPE.UPLOAD, id: upload_step?.id },
			{ type: STEPS_TYPE.DOCUMENT, id: document_step?.id },
		],
	}
}

export const getPayload = (values) => {
	try {
		const files = Array.isArray(values?.files)
			? values?.files.map((file) => ({
					user_type: file?.user_type?.value,
					file: file?.file,
			  }))
			: []

		const upload_step = Array.isArray(values?.steps)
			? values?.steps.find((step) => step?.type === STEPS_TYPE.UPLOAD)
			: undefined

		const document_step = Array.isArray(values?.steps)
			? values?.steps.find((step) => step?.type === STEPS_TYPE.DOCUMENT)
			: undefined

		const uploads_step = {
			type: STEPS_TYPE.UPLOAD,
			note: JSON.stringify(files),
		}
		if (upload_step) {
			Object.assign(uploads_step, { id: upload_step?.id })
		}

		const documents_step = {
			id: document_step?.id,
			type: STEPS_TYPE.DOCUMENT,
			documents: Array.isArray(values?.documents)
				? values?.documents.map((document) => JSON.parse(document?.value)?.id)
				: [],
			document_package: Array.isArray(values?.document_packages)
				? values?.document_packages.map(
						(document_package) => JSON.parse(document_package?.value)?.id,
				  )
				: [],
		}
		if (document_step) {
			Object.assign(documents_step, { id: document_step?.id })
		}

		return {
			title: values?.title,
			steps: [uploads_step, documents_step],
		}
	} catch (error) {
		notification('warning', 'Something went wrong while processing workflow creation')
	}
}

export const onCreate = (values) => {
	return request.post(apiEndpoints.workflow, values).then((response) => {
		if (response?.status === 200) {
			notification('success', response?.message)
		}
	})
}

export const onUpdate = (values, id) => {
	return request.patch(`${apiEndpoints.workflow}/${id}`, values).then((response) => {
		if (response?.status === 200) {
			notification('success', response?.message)
		}
	})
}

export default WorkflowForm
